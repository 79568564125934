import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _57f1df34 = () => interopDefault(import('../pages/theme-0/detail.vue' /* webpackChunkName: "pages/theme-0/detail" */))
const _312cf4b0 = () => interopDefault(import('../pages/theme-0/favorite-games/index.vue' /* webpackChunkName: "pages/theme-0/favorite-games/index" */))
const _0536f0c0 = () => interopDefault(import('../pages/theme-0/forgot-password.vue' /* webpackChunkName: "pages/theme-0/forgot-password" */))
const _825e8682 = () => interopDefault(import('../pages/theme-0/games/index.vue' /* webpackChunkName: "pages/theme-0/games/index" */))
const _58528838 = () => interopDefault(import('../pages/theme-0/login.vue' /* webpackChunkName: "pages/theme-0/login" */))
const _21766f50 = () => interopDefault(import('../pages/theme-0/no-support.vue' /* webpackChunkName: "pages/theme-0/no-support" */))
const _0d97b0fa = () => interopDefault(import('../pages/theme-0/not-found.vue' /* webpackChunkName: "pages/theme-0/not-found" */))
const _5bdfd634 = () => interopDefault(import('../pages/theme-0/play-game.vue' /* webpackChunkName: "pages/theme-0/play-game" */))
const _71904aa1 = () => interopDefault(import('../pages/theme-0/promotion/index.vue' /* webpackChunkName: "pages/theme-0/promotion/index" */))
const _afb7bb10 = () => interopDefault(import('../pages/theme-0/register.vue' /* webpackChunkName: "pages/theme-0/register" */))
const _12bbc0c1 = () => interopDefault(import('../pages/theme-0/player/deposits/index.vue' /* webpackChunkName: "pages/theme-0/player/deposits/index" */))
const _540de890 = () => interopDefault(import('../pages/theme-0/player/messages/index.vue' /* webpackChunkName: "pages/theme-0/player/messages/index" */))
const _a56512e0 = () => interopDefault(import('../pages/theme-0/player/profile.vue' /* webpackChunkName: "pages/theme-0/player/profile" */))
const _73fab801 = () => interopDefault(import('../pages/theme-0/player/wallets.vue' /* webpackChunkName: "pages/theme-0/player/wallets" */))
const _687937f0 = () => interopDefault(import('../pages/theme-0/player/withdrawals/index.vue' /* webpackChunkName: "pages/theme-0/player/withdrawals/index" */))
const _4e5fc4dc = () => interopDefault(import('../pages/theme-0/providers/games/index.vue' /* webpackChunkName: "pages/theme-0/providers/games/index" */))
const _71b52546 = () => interopDefault(import('../pages/theme-0/player/deposits/apply.vue' /* webpackChunkName: "pages/theme-0/player/deposits/apply" */))
const _ffa5deb8 = () => interopDefault(import('../pages/theme-0/player/withdrawals/apply.vue' /* webpackChunkName: "pages/theme-0/player/withdrawals/apply" */))
const _0a2f18cd = () => interopDefault(import('../pages/theme-0/index.vue' /* webpackChunkName: "pages/theme-0/index" */))
const _1a8c5c7a = () => interopDefault(import('../pages/theme-0/providers/games/_id.vue' /* webpackChunkName: "pages/theme-0/providers/games/_id" */))
const _066bdbf2 = () => interopDefault(import('../pages/theme-0/Information/_id.vue' /* webpackChunkName: "pages/theme-0/Information/_id" */))
const _d4fc6370 = () => interopDefault(import('../pages/theme-0/providers/_id.vue' /* webpackChunkName: "pages/theme-0/providers/_id" */))
const _4663bf82 = () => interopDefault(import('../pages/theme-0/_type/_id.vue' /* webpackChunkName: "pages/theme-0/_type/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/detail",
    component: _57f1df34,
    name: "detail"
  }, {
    path: "/favorite-games",
    component: _312cf4b0,
    name: "favorite-games"
  }, {
    path: "/forgot-password",
    component: _0536f0c0,
    name: "forgot-password"
  }, {
    path: "/games",
    component: _825e8682,
    name: "games"
  }, {
    path: "/login",
    component: _58528838,
    name: "login"
  }, {
    path: "/no-support",
    component: _21766f50,
    name: "no-support"
  }, {
    path: "/not-found",
    component: _0d97b0fa,
    name: "not-found"
  }, {
    path: "/play-game",
    component: _5bdfd634,
    name: "play-game"
  }, {
    path: "/promotion",
    component: _71904aa1,
    name: "promotion"
  }, {
    path: "/register",
    component: _afb7bb10,
    name: "register"
  }, {
    path: "/player/deposits",
    component: _12bbc0c1,
    name: "player-deposits"
  }, {
    path: "/player/messages",
    component: _540de890,
    name: "player-messages"
  }, {
    path: "/player/profile",
    component: _a56512e0,
    name: "player-profile"
  }, {
    path: "/player/wallets",
    component: _73fab801,
    name: "player-wallets"
  }, {
    path: "/player/withdrawals",
    component: _687937f0,
    name: "player-withdrawals"
  }, {
    path: "/providers/games",
    component: _4e5fc4dc,
    name: "providers-games"
  }, {
    path: "/player/deposits/apply",
    component: _71b52546,
    name: "player-deposits-apply"
  }, {
    path: "/player/withdrawals/apply",
    component: _ffa5deb8,
    name: "player-withdrawals-apply"
  }, {
    path: "/",
    component: _0a2f18cd,
    name: "index"
  }, {
    path: "/providers/games/:id",
    component: _1a8c5c7a,
    name: "providers-games-id"
  }, {
    path: "/Information/:id?",
    component: _066bdbf2,
    name: "Information-id"
  }, {
    path: "/providers/:id?",
    component: _d4fc6370,
    name: "providers-id"
  }, {
    path: "/:type/:id?",
    component: _4663bf82,
    name: "type-id"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
